<template>
	<edit-template class="refund">

    <t-card title="基础信息:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="180rem" label-position="left">
        <el-form-item label="订单编号：">
          {{ form.order_number }}
        </el-form-item>
        <el-form-item label="学生姓名：">
          {{ form.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号：">
          {{ form.student_idcard }}
        </el-form-item>
        <el-form-item label="入账学校：">
          {{ form.school }}
        </el-form-item>
        <template v-if="form.order_type !== 3">
          <el-form-item label="购买服务名称：">
            {{ form.goods_pack_name }}
          </el-form-item>
          <el-form-item label="当前客户归属招生老师：">
            {{ form.belong_teacher }}
          </el-form-item>
        </template>
      </el-form>
    </t-card>
    <t-card title="缴费详情:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="180rem" label-position="left" :rules="rules" :model="formData" ref="form">
        <el-form-item label="应缴金额：" style="margin-left: 12rem">
          {{ form.money_answer }}
        </el-form-item>
        <el-form-item label="是否缴费：" prop="is_pay">
          <el-select v-model="formData.is_pay" placeholder="请选择是否缴费">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="驳回原因：" prop="reason" v-if="formData.is_pay===2">
          <el-input v-model="formData.reason" maxlength="200" placeholder="请输入驳回原因" rows="8" show-word-limit="" style="width: 790rem" type="textarea"></el-input>
        </el-form-item>
        <div v-else="">
          <el-form-item label="实缴金额：" prop="paid_amount">
            <el-input v-model="formData.paid_amount" placeholder="请输入实缴金额" maxlength="10" @input="(v) => formData.paid_amount = v.replace(/[^\d.]/g, '').replace(/(\.\d{2}).+/, '$1')"></el-input>
          </el-form-item>
          <el-form-item label="缴费方式：" prop="payment_method">
            <el-input v-model="formData.payment_method" placeholder="请输入缴费方式" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="缴费时间：" prop="payment_at">
            <el-date-picker v-model="formData.payment_at" type="datetime" placeholder="请选择缴费时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="付款人姓名：" prop="payer">
            <el-input v-model="formData.payer" placeholder="请输入付款人姓名" maxlength="20"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </t-card>


    <template slot="footer">
      <el-button @click="onClick('form')" type="primary" v-if="formData.is_pay!==2">通过</el-button>
      <el-button @click="onClick('form')" type="primary" v-if="formData.is_pay!==1">驳回</el-button>
      <el-button @click="$router.back()">返回</el-button>
    </template>

    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false">
      <div style="text-align: center;margin-top: 12rem;font-weight: 700;font-size: 18rem">{{ formData.is_pay === 1 ? '是否确认学生缴费完成并通过审批 ?' : '驳回后家长端订单将自动取消，是否确认驳回?' }}</div>
      <div slot="footer" style="width: 100%;display: flex;justify-content: space-between;padding: 12rem 128rem">
        <el-button type="primary" @click="onCm">确 定</el-button>
        <el-button @click="dialogVisible = false">再看看</el-button>
      </div>
    </el-dialog>

</edit-template>
</template>

<script>
import Card from "@/components/Card";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"审批"}}},
  data() {
    let amount = (rule, value, callback) => {
      if (0 > value || value > this.form.money_answer) {
        callback(new Error('实缴金额应小于应缴金额'));
      } else {
        callback();
      }
    };
    return {
      titleStyle: {
        borderBottom: '1rem solid #eee',
        paddingBottom: '10rem',
        display: 'block'
      },
      form: {},
      formData: {
        payment_at: new Date().toLocaleString().replace(/\//g, '-'),
      },
      loading: false,
      dialogVisible: false,
      rules: {
        is_pay: [
          {required: true, message: '请选择是否缴费', trigger: 'change'}
        ],
        reason: [
          {required: true, message: '请输入驳回原因', trigger: 'change'}
        ],
        paid_amount: [
          {required: true, message: '请输入实缴金额', trigger: 'change'},
        ],
        payment_method: [
          {required: true, message: '请输入缴费方式', trigger: 'change'}
        ],
        payment_at: [
          {required: true, message: '请选择缴费时间', trigger: 'change'}
        ],
        payer: [
          {required: true, message: '请输入付款人姓名', trigger: 'change'}
        ],
      }
    }
  },
  mounted() {
    this.$_register.get("api/recruit-v2/order-offline/show?id=" + this.$route.query.id).then(res => {
      this.form = res.data.data;
    })
  },
  methods: {
    onClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = true
        }
      });
    },
    onCm() {
      const {is_pay, paid_amount, payment_method, payment_at, payer, reason} = this.formData;
      const postData = is_pay === 1 ? {is_pay, paid_amount, payment_method, payment_at, payer} : {is_pay, reason};
      const requestBody = Object.assign({}, postData, {id: this.$route.query.id});
      this.$_register.post("api/recruit-v2/order-offline/edit", requestBody).then(() => {
        this.$router.replace('/finance/enrollment/offline-payment-approval/look?id=' + this.$route.query.id);
      });
    },
    handleClose() {
      this.dialogVisible = false
    },
  },
  components: {
    TCard: Card,
  }
}
</script>

<style lang="scss" scoped>
.refund {
  background: transparent !important;

  .card {
    background: white;
    padding: 10rem;
    margin-bottom: 20rem;

    .content {
      padding: 20rem 30rem;
    }
  }

  .footer-button {
    background: white;
    border: none;
    line-height: 80rem;
  }

  .timeline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20rem;

    .left {
      border-radius: 50%;
      background: #1a1d7a;
      width: 20rem;
      height: 20rem;
    }

    .right {
      flex: 1;
      margin-left: 20rem;
    }
  }

  .timeline:last-child {
    margin-bottom: 0;
  }
}

::v-deep .el-textarea {
  width: 15.06944444444444vw;
}

@media only screen and (max-width: 1139rem) {
  .el-textarea {
    max-width: 100%;
    width: 250rem !important;
  }
}
</style>
